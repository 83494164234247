import React from "react";
import Layout from "../components/layout";
import Axios from "axios";
import {navigate} from "gatsby"
import SearchedArticle from "../components/searched-article";

async function searchArticles(searchQuery) {
    const URL = `${process.env.GATSBY_API_URL}` + '/articles';
    const request = await Axios.get(URL, {
        params: {
            '_where[_or][0][title_contains]': searchQuery,
            '_where[_or][1][description_contains]': searchQuery,
            '_where[_or][2][content_contains]': searchQuery,
        }
    });
    navigate("/search", {
        state: {
            searchedArticles: request.data
        },
    })
}

export default class Search extends React.Component{

    state = {
        searchQuery: ""
    };

    handleInputChange = event => {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
            [name]: value,
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        searchArticles(this.state.searchQuery).catch(() => this.setState({ state: this.state }));
    };

    searchedOutput() {
        if (this.props.location.state && this.props.location.state.searchedArticles && this.props.location.state.searchedArticles.length > 0) {
            return (
                <div className="category-article-list">
                    <div><span>Wyniki wyszukiwania:</span></div>
                    <div className="article-list">
                        {this.props.location.state.searchedArticles.map((article, i) => {
                            return (
                                <SearchedArticle
                                    key={i}
                                    article={article}
                                />
                            );
                        })}
                    </div>
                </div>
            )
        }
        else if(this.props.location.state && this.props.location.state.searchedArticles === undefined) {
            return <p></p>
        }
        else {
            return <p>Brak wyników, spróbuj ponownie.</p>
        }
    }

    render() {
        return (
            <Layout>
                <div className="page-wrapper">
                    <div className="page-container search-page">
                        <div className="category-article-list">
                            <div className="search">
                                <form onSubmit={this.handleSubmit} className="search">
                                    <input
                                        placeholder="Wyszukaj szukaną frazę" required
                                        className="search-input"
                                        type="text"
                                        name="searchQuery"
                                        value={this.state.searchQuery}
                                        onChange={this.handleInputChange}
                                    />
                                    <button type="submit" className="btn main-btn">Szukaj</button>
                                </form>
                            </div>
                            {this.searchedOutput()}
                        </div>

                    </div>
                </div>
            </Layout>
        )
    }

};
