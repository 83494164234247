import React from "react";
import { Link } from "gatsby";
import ResponsiveArticleImage from "./responsive-article-image";
import CategoryNameComponent from "./presentation-components/category-name.component";

const SearchedArticle = ({ article }) => {
    return (
        <div className="article-item">
            <Link to={`/article/${article.slug}`} className="article-link">
                <div className="article-item-content">
                    <div className="image-wrap">
                        <div className="gradient"> </div>
                        <ResponsiveArticleImage formats={article.image.formats} alt={article.title}></ResponsiveArticleImage>
                        <CategoryNameComponent category={article.category}></CategoryNameComponent>
                    </div>
                    <div className="article-text">
                        <p className="article-title">
                            {article.title}
                        </p>
                        <p className="article-beginning">
                            {article.description}
                        </p>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default SearchedArticle;
